<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>

                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Registro audit team approval stage 2</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(registrarAuditTeamApproval)">
                                            <b-row class="mt-3">
                                                <b-col md="12">
                                                    <validation-provider name="estándar del sistema de gestión" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Estándar del sistema de gestión:" class="mb-2">
                                                            <v-select @input="checkNormas" multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditTeamApproval.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditTeamApproval.normas" :options="listaNormas">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-form-group label="Tipo de Certificación:" class="mb-2">
                                                        <b-form-checkbox-group small plain @change="datosAuditTeamApproval.tipoCertificacion = $event" :value="datosAuditTeamApproval.tipoCertificacion" :options="[{value:1,text:'Certificación inicial'}]"></b-form-checkbox-group>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="12">
                                                    <validation-provider name="tipo de auditoría" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                        <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                            <v-select multiple :reduce="listaTipoAuditoria => listaTipoAuditoria.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditTeamApproval.tipoAuditoria.length>0, 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditTeamApproval.tipoAuditoria" :options="listaTipoAuditoria">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-form-group label="Metodología de auditoría:" class="mb-2">
                                                        <b-form-checkbox-group small plain @change="datosAuditTeamApproval.metodologiaAuditoria = $event" :value="datosAuditTeamApproval.metodologiaAuditoria" :options="[{value:1,text:'Auditoría in situ'}, {value:2,text:'Auditoría remota'}]"></b-form-checkbox-group>
                                                    </b-form-group>
                                                </b-col>
                                                <!-- <b-col md="6">
                                                    <validation-provider name="cliente" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                        <b-form-group label="Cliente:" class="mb-2">
                                                            <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAuditTeamApproval.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosAuditTeamApproval.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="6">
                                                    <validation-provider name="número de contrato" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Número de contrato:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de contrato" v-model.lazy="datosAuditTeamApproval.numeroContrato"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- <b-col md="6">
                                                            <validation-provider name="fecha" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group label="Fecha:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosAuditTeamApproval.datosArray1[0].fecha"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> -->
                                                <b-col md="12">
                                                    <validation-provider name="alcance">
                                                        <b-form-group label="Alcance:" class="mb-2">
                                                            <quill-editor ref="quillAlc" v-model="datosAuditTeamApproval.alcance" :options="editorOption">
                                                            </quill-editor>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12" class="mb-2">
                                                    <b-table-simple bordered show-empty small responsive outlined>
                                                        <!-- <b-thead>
                                                                    <b-tr>
                                                                        <b-th colspan="2">Procesos no aplicables para el alcance de la certificación:</b-th>
                                                                    </b-tr>
                                                                </b-thead> -->
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="30%" rowspan="2" colspan="2">
                                                                    EA / NACE:
                                                                </b-td>
                                                                <b-td width="40%" colspan="3">
                                                                    ANZSIC (según 2006):
                                                                </b-td>
                                                                <td width="30%" colspan="2" rowspan="2">
                                                                    <b-form-group label="ICA QP:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].icaQp" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                </td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="3">
                                                                    <b-row>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Subdivisión:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese la subdivisión" v-model.lazy="datosAuditTeamApproval.datosArray1[0].subdivision"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Clase:" class="mb-2">
                                                                                <b-form-input placeholder="Ingrese la clase" v-model.lazy="datosAuditTeamApproval.datosArray1[0].clase"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="15%">
                                                                    Tipo de auditoría:
                                                                </b-td>
                                                                <b-td colspan="6">
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].tipoAuditoria"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td rowspan="6">
                                                                    Equipo auditor aprobado:
                                                                </b-td>
                                                                <b-td>

                                                                </b-td>
                                                                <b-td width="13%">
                                                                    IA-Estadio I
                                                                </b-td>
                                                                <b-td width="13%">
                                                                    IA-Estadio II
                                                                </b-td>
                                                                <b-td width="14%">
                                                                    SA I
                                                                </b-td>
                                                                <b-td width="15%">
                                                                    SA II
                                                                </b-td>
                                                                <b-td width="15%">
                                                                    SA III
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Programa de auditoría
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input1"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input2"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input3"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input4"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input5"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Auditor líder
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input6"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input7"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input8"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input9"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input10"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Auditor 1/2
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input11"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input12"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input13"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input14"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input15"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    Auditor 3/4
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input16"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input17"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input18"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input19"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input20"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    TE
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input21"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input22"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input23"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input24"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input25"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="2">
                                                                    Justificación para la aprobación del equipo de auditoría
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input26"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input27"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input28"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input29"></b-form-input>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].input30"></b-form-input>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="7">
                                                                    <quill-editor ref="quillRaieaa" v-model.lazy="datosAuditTeamApproval.datosArray1[0].revisoTrasAmenaza" :options="editorOption">
                                                                    </quill-editor>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="7">
                                                                    <b-form-group label="¿Ha recibido el equipo de auditoría identificado alguna retroalimentación negativa de los clientes o mediante el monitoreo del desempeño?:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].recibidoEquipoAuditoria" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                    <b-form-group label="¿El AL / Auditor está identificado, calificado para el código según la evaluación de competencia?:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].auditorIdentificado" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                    <b-form-group label="En caso negativo, si el AL / Auditor puede ser aprobado para una asignación específica con justificación:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].casoNegativo" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                    <b-form-group label="Si la respuesta es No, use TE:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].respuestaNo" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                    <b-form-group label="¿Está TE aprobado para la categoría EA / NACE / ANZSIC / Cadena alimentaria?:" class="mb-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].teAprobado" :options="comboSiNo"></b-form-radio-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="7">
                                                                    <b-row>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Equipo auditor aprobado por ECC:" class="mb-2">
                                                                                <b-form-input placeholder="Escriba aquí" v-model.lazy="datosAuditTeamApproval.datosArray1[0].equipoAuditorAprobado"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosAuditTeamApproval.datosArray1[0].fecha"></b-form-input>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="7">
                                                                    <span>Observaciones:</span>
                                                                    <b-form-group label="Confirme que los comentarios de los CDC de la auditoría anterior hayan sido revisados e incluidos:" class="my-2">
                                                                        <b-form-radio-group small plain v-model.lazy="datosAuditTeamApproval.datosArray1[0].confirmeComentarios" :options="[{value:1,text:'SI'},{value:2,text:'NO'},{value:3,text:'NA'}]"></b-form-radio-group>
                                                                    </b-form-group>
                                                                    <b-form-group label="Comentarios de los CDC de la auditoría anterior (si corresponde):" class="mb-2">
                                                                        <quill-editor ref="quillComcdca" v-model.lazy="datosAuditTeamApproval.datosArray1[0].comentariosCdc" :options="editorOption">
                                                                        </quill-editor>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        Guardar <i class="fas fa-save"></i>
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>

                                    <!-- <b-row v-if="$route.params.id" class="text-center mt-2">
                                        <b-col md="12">
                                            <b-button class="mr-2" variant="dark" :to="{name: 'Ingreso de documentos Stage 2'}" type="button">
                                                <i class="fas fa-arrow-left"></i> Volver
                                            </b-button>
                                        </b-col>
                                    </b-row> -->

                                    <!--  </b-form> -->
                                    <!-- </validation-observer> -->
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import translate from "translate";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            isTemplate: true,
            editorOption: {
                placeholder: 'Escriba aqui...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        /* ['blockquote', 'code-block'], */
                        [{
                            'header': 1
                        }, {
                            'header': 2
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'indent': '-1'
                        }, {
                            'indent': '+1'
                        }],
                        [{
                            'direction': 'rtl'
                        }],
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],
                        [{
                            'header': [1, 2, 3, 4, 5, 6, false]
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'align': []
                        }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },
            datosAuditTeamApproval: {
                idAuditTeamApproval: '',
                idCliente: null,
                idAuditoria: null,
                normas: [],
                tipoCertificacion: [],
                tipoAuditoria: [],
                metodologiaAuditoria: [],
                numeroContrato: '',
                alcance: '',

                datosArray1: [{
                    icaQp: 1,
                    subdivision: '',
                    clase: '',
                    tipoAuditoria: '',
                    input1: '',
                    input2: '',
                    input3: '',
                    input4: '',
                    input5: '',
                    input6: '',
                    input7: '',
                    input8: '',
                    input9: '',
                    input10: '',
                    input11: '',
                    input12: '',
                    input13: '',
                    input14: '',
                    input15: '',
                    input16: '',
                    input17: '',
                    input18: '',
                    input19: '',
                    input20: '',
                    input21: '',
                    input22: '',
                    input23: '',
                    input24: '',
                    input25: '',
                    input26: '',
                    input27: '',
                    input28: '',
                    input29: '',
                    input30: '',
                    revisoTrasAmenaza: 'Se revisó tras la amenaza a la imparcialidad con el equipo de auditoría y es aceptable: a.	Interés propio: El equipo de auditoría no tiene ninguna relación con la organización del cliente y, por lo tanto, la amenaza de interés propio a la imparcialidad no es aplicable. b.	Amenaza de auto revisión: Ninguno de los miembros del equipo de auditoría ha brindado servicios de consultoría a la organización del cliente y, por lo tanto, la amenaza de autoevaluación a la imparcialidad no es aplicable c.	Amenaza de familiaridad: Los miembros del equipo de auditoría no están familiarizados con la organización del cliente a través de amigos que trabajan o participaciones privadas, excepto por la interfaz durante la evaluación planificada. Teniendo en cuenta el número de auditorías anuales realizadas por los miembros del equipo de auditoría y la interfaz con diferentes clientes y diferentes sistemas de gestión durante el año, no se considera que la amenaza de familiaridad tenga ningún impacto en el resultado de la auditoría y el objetivo de la auditoría. Por lo tanto, la amenaza de familiaridad a la imparcialidad no se considera un riesgo para el resultado de la auditoría y también es revisada más a fondo por el comité de decisión de certificación basándose en la evidencia de auditoría. d.	Amenaza de intimidación: No se percibe una amenaza por parte de la organización cliente considerando las negociaciones iniciales y las correspondencias relacionadas. Por lo tanto, la amenaza de intimidación a la imparcialidad no es aplicable. Como política, IQC no admite intimidación, independientemente del resultado de las negociaciones. ',
                    recibidoEquipoAuditoria: 1,
                    auditorIdentificado: 1,
                    casoNegativo: 1,
                    respuestaNo: 1,
                    teAprobado: 1,
                    equipoAuditorAprobado: '',
                    fecha: null,
                    confirmeComentarios: 1,
                    comentariosCdc: '',
                }],

            },

            //Disabled
            disabledNorma1: false,
            disabledNorma2: false,
            disabledNorma3: false,
            disabledNorma4: false,
            disabledNorma5: false,
            disabledNorma6: false,
            //Listas
            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            comboSiNo: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                }
            ],
            listaTipoAuditoria: [{
                idTipoAuditoria: 1,
                descripcion: 'Auditoría individual',
            }, {
                idTipoAuditoria: 2,
                descripcion: 'Auditoría integrada',
            }, {
                idTipoAuditoria: 3,
                descripcion: 'Auditoría conjunta',
            }, {
                idTipoAuditoria: 4,
                descripcion: 'Auditoría articulada',
            }, {
                idTipoAuditoria: 5,
                descripcion: 'No aplica',
            }, ],
        }

    },
    methods: {
        descargarDocumento(url) {
            window.open(url)
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        checkNormas(e) {
            !!e.find(x => x == 1) ? this.disabledNorma1 = true : this.disabledNorma1 = false;
            !!e.find(x => x == 2) ? this.disabledNorma2 = true : this.disabledNorma2 = false;
            !!e.find(x => x == 3) ? this.disabledNorma3 = true : this.disabledNorma3 = false;
            !!e.find(x => x == 4) ? this.disabledNorma4 = true : this.disabledNorma4 = false;
            !!e.find(x => x == 5) ? this.disabledNorma5 = true : this.disabledNorma5 = false;
            !!e.find(x => x == 6) ? this.disabledNorma6 = true : this.disabledNorma6 = false;
        },

        obtenerAuditTeamApproval() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-pack-audit-tema-approval-s2", {
                    params: {
                        idAuditTeamApproval: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    //me.datosAuditTeamApproval = response.data[0];
                    me.datosAuditTeamApproval.normas = JSON.parse(response.data[0].normas);
                    me.datosAuditTeamApproval.tipoCertificacion = JSON.parse(response.data[0].tipoCertificacion);
                    me.datosAuditTeamApproval.tipoAuditoria = JSON.parse(response.data[0].tipoAuditoria);
                    me.datosAuditTeamApproval.metodologiaAuditoria = JSON.parse(response.data[0].metodologiaAuditoria);
                    me.datosAuditTeamApproval.numeroContrato = response.data[0].numeroContrato;
                    me.datosAuditTeamApproval.alcance = response.data[0].alcance;
                    me.datosAuditTeamApproval.datosArray1 = JSON.parse(response.data[0].datosArray1);
                    me.datosAuditTeamApproval.estado = response.data[0].estado;
                    me.datosAuditTeamApproval.idAuditTeamApproval = response.data[0].idAuditTeamApproval;
                    me.datosAuditTeamApproval.idCliente = response.data[0].idCliente;
                    me.datosAuditTeamApproval.idAuditoria = response.data[0].idAuditoria;

                    !!me.datosAuditTeamApproval.normas.find(x => x == 1) ? me.disabledNorma1 = true : me.disabledNorma1 = false;
                    !!me.datosAuditTeamApproval.normas.find(x => x == 2) ? me.disabledNorma2 = true : me.disabledNorma2 = false;
                    !!me.datosAuditTeamApproval.normas.find(x => x == 3) ? me.disabledNorma3 = true : me.disabledNorma3 = false;
                    !!me.datosAuditTeamApproval.normas.find(x => x == 4) ? me.disabledNorma4 = true : me.disabledNorma4 = false;
                    !!me.datosAuditTeamApproval.normas.find(x => x == 5) ? me.disabledNorma5 = true : me.disabledNorma5 = false;
                    !!me.datosAuditTeamApproval.normas.find(x => x == 6) ? me.disabledNorma6 = true : me.disabledNorma6 = false;
                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosAuditTeamApproval.isPack = true;
                        me.datosAuditTeamApproval.idAuditTeamApproval = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosAuditTeamApproval.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosAuditTeamApproval.idUsuario = me.$store.state.user.uid;
                        me.datosAuditTeamApproval.sUsuario = me.$store.state.user.username;
                        me.datosAuditTeamApproval.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!'+error)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        registrarAuditTeamApproval() {

            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-pack-audit-tema-approval-s2", {
                        datosAuditTeamApproval: JSON.stringify(me.datosAuditTeamApproval)
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!'+error)
                });

        },

        traducir(texto) {
            return translate(texto, {
                from: "es",
                to: "en",
                engine: "google",
                key: "AIzaSyBfEPrAhMQ9wHfGhGU6B_N0uB_YyKlfK94",
                cache: 0
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },

    },
    watch: {

    },
    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosAuditTeamApproval.idCliente = user.uidClient;
            // this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerAuditTeamApproval();
            }
        }

    }

}
</script>

<style>
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    cursor: not-allowed !important;
    background-color: #ffffff !important;
}
</style>
